import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { EmployeeContext } from "../../context/EmployeeContext";
import Header from "../Header";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EmployeeSettings from "../Employee/EmployeeSettings";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { CircularProgressbar } from "react-circular-progressbar";
import IconButton from "@mui/material/IconButton";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
// import SearchIcon from '@mui/icons-material/Search';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import manager from '../../images/manager.png';
import supervisor from '../../images/supervisor.png';
import csa from '../../images/csa.png';

export default function Home() {
  const { employees } = useContext(EmployeeContext);
  const [employeesData, setEmployeesData] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [searchEmployee, setSearchEmployee] = useState("");
  const [message, setMessage] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [selectSite, setSelectSite] = useState("");
  const [siteResult, setSiteResult] = useState([]);
  const [showSiteResult, setShowSiteResult] = useState(false);
  const [page, setPage] = useState(0)
  const [numberPages, setNumberPages] = useState("")
  const [user, setUser] = useState("")


  useEffect(async () => {
    let employees = localStorage.getItem("employees")
    setEmployeesData(JSON.parse(employees))
    setUser(localStorage.getItem("user"))
    const data = {
      name: localStorage.getItem("user")
    }
    axios.post("https://backend.training.racetrackpitstop.co.uk/trainingAdmin/noPages", data)
      .then((res) => {
        setNumberPages(res.data)
        // console.log(res.data)
      }).catch((err) => {
        console.log(err)
      })

  }, [])

  const search = async (name11) => {
    const data = {
      name: user,
      search: name11,
    }
    if (name11.length > 2) {

      await axios
        .post("https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staff", data)
        .then((res) => {
          setSearchData(res.data);
          setShowResults(true);
          if (res.data.length <= 0) {
            setMessage(`No Employees Found Matching "${name11}"`);
          } else {
            setMessage("");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (name11.length <= 0) {
      setSearchData([]);
      setShowResults(false);
      setMessage("");
      setSearchEmployee("")
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      search(searchEmployee);
    }
  };



  const siteSearch = (site) => {
    if (selectSite === "All" || selectSite === "Select") {
      setEmployeesData(employees);
    } else {
      const siteData = employees.filter((e) => e.site === site);
      setEmployeesData(siteData);
      setSelectSite(site);
    }
  };

  const show = () => {
    if (showSearch) {
      setShowSearch(false);
      setShowResults(false);
      setSearchData([]);
    } else {
      setShowSearch(true);
    }
  };

  const removeStaff = async (name, role, site) => {
    var data = {
      name: name,
      role: role,
      site: site,
    };
    var x = window.confirm(`Are you sure you want to remove '${name}'?`);
    if (x === true) {
      await axios
        .post("https://backend.training.racetrackpitstop.co.uk/trainingAdmin/remove", data)
        .then((res) => {
          alert(`'${name}' Has Been Removed From The System.`);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      window.location.reload();
    }
  };

  async function handleprevious() {
    const newPage = page - 1
    const data = {
      name: user,
      page: newPage
    }
    console.log(data)
    if (page === 0) {
      alert("You are on the first page")
    } else {
      await axios.post(`https://backend.training.racetrackpitstop.co.uk/trainingAdmin/nextPage`, data).then((res) => {
        // console.log(res.data)
        setEmployeesData(res.data)
        setPage(page - 1)

      }).catch((err) => {
        console.log(err)
      })
    }
  }

  async function handleNext() {
    const newPage = page + 1
    const data = {
      name: user,
      page: newPage
    }
    if (page === parseInt(numberPages)) {
      alert("You are on the last page")
    } else {
      await axios.post(`https://backend.training.racetrackpitstop.co.uk/trainingAdmin/nextPage`, data).then((res) => {
        console.log(res.data)
        // localStorage.setItem("employees",JSON.stringify(res.data))
        // let e1 = localStorage.getItem("employees")
        //setEmployeesData(JSON.parse(e1))
        setEmployeesData(res.data)
        setPage(page + 1)

      }).catch((err) => {
        console.log(err)
      })
    }
  }

  return (
    <div>
      <Header />
      <h1>Staff Training</h1>
      <br />
      <div className="searchbar">
        <input
          type="text"
          className="searchBar"
          onChange={(e) => {
            { search(e.target.value); setSearchEmployee(e.target.value) }
          }}
          placeholder="Enter Employee Name..."
          onKeyDown={handleKeyDown}
          focus
        />
      </div>

      <p
        style={{
          textAlign: "center",
          border: "1px solid black",
          width: "75%",
          margin: "0 auto",
        }}
      >
        <b>{message}</b>
      </p>

      {showResults ? (
        <div className="searchResults">
          {searchData.map(function (d, i) {
            return (
              <div key={i}>
                <p
                  style={{
                    padding: "5px",
                    backgroundColor: "#f1f1f1",
                    border: "2px solid #BF1a1a",
                    width: "90%",
                    margin: "auto",
                  }}
                >
                  <a
                    href={`/employee?name=${d.name}&role=${d.role}&site=${d.site}`}
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                      color: "#333",
                    }}
                  >
                    {d.name} @ {d.site}
                  </a>
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
      <div className="employees">
        {employeesData.length > 0 ? (
          employeesData.map(function (d, i) {
            var hs;
            d.area1
              ? (hs = <DoneIcon fontSize="medium" className="tick" />)
              : (hs = <CloseIcon fontSize="medium" className="cross" />);
            var hot;
            d.area2
              ? (hot = <DoneIcon fontSize="medium" className="tick" />)
              : (hot = <CloseIcon fontSize="medium" className="cross" />);
            var ms;
            d.area3
              ? (ms = <DoneIcon fontSize="medium" className="tick" />)
              : (ms = <CloseIcon fontSize="medium" className="cross" />);
            var fa;
            d.area4
              ? (fa = <DoneIcon fontSize="medium" className="tick" />)
              : (fa = <CloseIcon fontSize="medium" className="cross" />);
            return (
              <div className="employee">
                <div className="mainProgress" style={{ height: "1%" }}>
                  <CircularProgressbar
                    value={d.percent}
                    text={`${d.percent}%`}
                  />
                </div>
                <p key={i}>
                  <span
                    style={{ color: "#1d1d1d", textTransform: "uppercase" }}
                  >
                    {d.name}<br /><span style={{ color: "#BF1a1a", textAlign: "center" }}>ID: {d.staffTrainingId}</span>
                    {d.role === "Manager" ? (
                      <img src={manager} alt="manager" />
                    ) : d.role === "Supervisor" ? (
                      <img src={supervisor} alt="Supervisor" />
                    ) : d.role === "CSA" ? (
                      <img src={csa} alt="CSA" />
                    ) : (
                      <></>
                    )}
                  </span>
                  {!d.email ? (
                    <span className="alert">
                      <ReportProblemIcon color="warning" /> No Email
                    </span>
                  ) : (
                    <></>
                  )}
                  <span className="site" style={{ color: "#BF1a1a", fontWeight: "800" }}>{d.site}</span>
                  <div className="shown">
                    <span>H&amp;S</span>
                    <span>{hs}</span>
                  </div>
                  <div className="Notshown">
                    <span>Floor</span>
                    <span>{hot}</span>
                  </div>
                  <div className="shown">
                    <span>Alcohol</span>
                    <span>{ms}</span>
                  </div>
                  <div className="shown">
                    <span>Tills</span>
                    <span>{fa}</span>
                  </div>
                </p>
                <div style={{ marginTop: "15px" }}>
                  <Tooltip title="Remove Staff">
                    <button
                      onClick={() => {
                        removeStaff(d.name, d.role, d.site);
                      }}
                    >
                      <PersonRemoveIcon />
                    </button>
                  </Tooltip>
                  <Tooltip title="View Employee">
                    <button>
                      <a
                        href={`/employee?name=${d.name}&role=${d.role}&site=${d.site}`}
                      >
                        <VisibilityIcon />
                      </a>
                    </button>
                  </Tooltip>
                </div>
              </div>
            );
          })
        ) : (
          <h4 style={{ textAlign: "center" }}>
            No Employees Could Be Found @ {selectSite}
          </h4>
        )}
      </div>
      <div className="pages">
        <IconButton onClick={() => handleprevious()}>
          <SkipPreviousIcon fontSize="large" color="error" />
        </IconButton>
        <p>Page: {page + 1} / {parseInt(numberPages) + 1} </p>
        <IconButton onClick={() => handleNext()}>
          <SkipNextIcon fontSize="large" color="error" />
        </IconButton>
      </div>
    </div>
  );
}
