import { useRef } from 'react'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DownloadIcon from '@mui/icons-material/Download';
import Logo from '../images/rt-logo.png'
import Signature from '../images/shamlySig.PNG'
import ReactToPrint from "react-to-print";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    bgcolor: "background.paper",
    border: "4px solid #BF1a1a",
    borderRadius: "1rem",
    backgroundColor: "#ffff",
    boxShadow: 24,
    p: 4,
    color: "#fff",
    height: "95vh",
    overflowY: "scroll"
};

export default function Certificate(props) {

    const componentRef = useRef();
    const myRefname = useRef(null);

    const linkToPrint = () => {
        return (
            <button ref={myRefname} style={{ display: "none" }}>Print PDF</button>
        )
    }

    const handleClick = () => {
        myRefname.current.click();
    }

    return (
        <div style={{ color: "#000" }}>
            <Box sx={style}>
                <div id="certificate" style={{margin: "10px" }} ref={componentRef} >
                    <div id="certHeader" style={{ textAlign: "center" }}>
                        <img style={{ width: "300px",marginTop:"20px"}} src={Logo} alt="RaceTrack Logo" />
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            <h2>Training Record</h2>
                        </Typography>
                        <p style={{ marginTop: "5%", color: "black" }}><b>Module: </b> <u> {props.module} </u></p>
                    </div>

                    <div id="certContent" style={{textAlign:"justify",margin:"25px"}}>
                    <p style={{ marginTop: "12%", color: "black"}}>This certificate is to confirm that {props.name} employed at {props.site} as a {props.role} has passed their '{props.module}' training module.</p>
                        <br />
                        <div style={{color: "black", textAlign: "justify" }}>
                            <p style={{ textAlign: "justify" }}>The following tasks were completed in this module:</p>
                            {props.tasks.map(function (d, i) {
                                return (
                                    <li style={{ marginLeft: "5%" }}><b>{d.task}</b> - {d.dateTrained}</li>
                                )
                            })}
                        </div>
                            <br />
                            <p  style={{color: "black", textAlign: "justify" }}>Please keep a copy of this certificate in your records.</p>
                    </div>

                    <div id="certFooter"  style={{ margin: "5%", color: "#000", display: "flex", justifyContent: "space-between", position: "relative" }}>
                        <div>
                            <p style={{ marginBottom: "6%" }}>Signed on behalf of the company </p>
                            <img width="200px" src={Signature} />
                            <p><b>Shamly Sud</b></p>
                        </div>
                        <div style={{ position: "absolute", bottom: 0, right: 0 }}>
                            <p><b style={{ marginRight: "10px" }}>Date: </b> <u> {props.date}</u></p>
                        </div>
                    </div>
                </div>
                <button onClick={() => handleClick()} style={{ position:"absolute", top:0, right:"5%", padding: "5px", color: "white", backgroundColor: "green", border: "3px solid black", borderRadius:"50%"}}><DownloadIcon sx={{ color: "#ffff" }} /></button>
                <ReactToPrint documentTitle={`${props.name}_${props.module}`} trigger={linkToPrint} content={() => componentRef.current} />
            </Box>
        </div>
    )
}