import Header from "./Header";
import axios from "axios";
import { useState, useEffect } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { CircularProgressbar } from "react-circular-progressbar";

export default function Reports() {
  const [sites, setSites] = useState([]);
  const [showSites, setShowSites] = useState(false);
  const [employeesData, setEmployeesData] = useState([]);
  const [site, setSite] = useState("");
  const [supervisors, setSupervisors] = useState([]);
  const [csas, setCsas] = useState([]);
  const [managers, setManagers] = useState([])
  const [managerAv, setManagerAv] = useState()
  const [superAv, setSuperAv] = useState()
  const [csaAv, setCsaAv] = useState()
  const [emails, setEmails] = useState(0)
  const [newStaff, setNewStaff] = useState({})
  const [oldStaff, setOldStaff] = useState({})
  const [staffLogged, setStaffLogged] = useState({})
  const [parentTasks, setParentTasks] = useState({})
  const [childTasks, setChildTasks] = useState({})
  const [parentTasksAllTM, setParentTasksAllTM] = useState([])
  const [parentTasksAllLM, setParentTasksAllLM] = useState([])
  const [childTasksAllTM, setChildTasksAllTM] = useState([])
  const [childTasksAllLM, setChildTasksAllLM] = useState([])

  useEffect(async () => {
    const user = localStorage.getItem("user")
    await axios
      .get(`https://backend.training.racetrackpitstop.co.uk/trainingAdmin/reporting/${user}`)
      .then((res) => {
        // console.log(res.data);
        setSites(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function showSiteTraining(site) {
    setSite(site);
    axios
      .get(`https://backend.training.racetrackpitstop.co.uk/trainingAdmin/site?site=${site}`)
      .then((res) => {
        // console.log(res.data);
        setEmployeesData(res.data);
        let csa = []
        let manager = []
        let supervisor = []
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].role === "CSA") {
            csa.push({
              role: res.data[i].role,
              percent: res.data[i].percent
            })

          } else if (res.data[i].role === "Supervisor") {
            supervisor.push({
              role: res.data[i].role,
              percent: res.data[i].percent
            });
          } else if (res.data[i].role === "Manager") {
            manager.push({
              role: res.data[i].role,
              percent: res.data[i].percent
            })
          }
        }
        setCsas(csa)
        setSupervisors(supervisor)
        setManagers(manager)
        let managerAverage = 0
        for (let i = 0; i < manager.length; i++) {
          managerAverage += parseInt(manager[i].percent)
        }
        let managersAv = managerAverage / manager.length

        let supervisorAverage = 0
        for (let i = 0; i < supervisor.length; i++) {
          supervisorAverage += parseInt(supervisor[i].percent)
        }
        let supervisorAv = supervisorAverage / supervisor.length

        let csasAverage = 0
        for (let i = 0; i < csa.length; i++) {
          csasAverage += parseInt(csa[i].percent)
        }
        let csaAvg = csasAverage / csa.length


        setSuperAv((supervisorAv).toFixed())
        setManagerAv((managersAv).toFixed())
        setCsaAv((csaAvg).toFixed())
        setShowSites(true);

        //get staff with emails on DB
        let email = 0
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].email) {
            email = email + 1
          } else {
            continue
          }
        }
        setEmails(email)
      })
      .catch((err) => {
        console.log(err);
      });

    axios.get(`https://backend.training.racetrackpitstop.co.uk/reporting/staff/new?site=${site}`).then((res) => {
      setNewStaff(res.data)
    }).catch((err) => {
      console.log(err)
    })

    axios.get(`https://backend.training.racetrackpitstop.co.uk/reporting/staff/old?site=${site}`).then((res) => {
      setOldStaff(res.data)
    }).catch((err) => {
      console.log(err)
    })

    axios.get(`https://backend.training.racetrackpitstop.co.uk/reporting/staff/logged?site=${site}`).then((res) => {
      setStaffLogged(res.data)
    }).catch((err) => {
      console.log(err)
    })

    axios.get(`https://backend.training.racetrackpitstop.co.uk/reporting/tasks/completed/parent?site=${site}`).then((res) => {
      console.log(res.data)
      setParentTasks(res.data)
      setParentTasksAllLM(res.data.lastMonthData.slice(0, 5))
      setParentTasksAllTM(res.data.thisMonthData.slice(0, 5))
    }).catch((err) => {
      console.log(err)
    })

    axios.get(`https://backend.training.racetrackpitstop.co.uk/reporting/tasks/completed/child?site=${site}`).then((res) => {
      setChildTasks(res.data)
      setChildTasksAllLM(res.data.lastMonthData.slice(0, 5))
      setChildTasksAllTM(res.data.thisMonthData.slice(0, 5))
    }).catch((err) => {
      console.log(err)
    })
  }

  const removeStaff = async (name, role, site) => {
    var data = {
      name: name,
      role: role,
      site: site,
    };
    var x = window.confirm(`Are you sure you want to remove '${name}'?`);
    if (x === true) {
      await axios
        .post("https://backend.training.racetrackpitstop.co.uk/trainingAdmin/remove", data)
        .then((res) => {
          alert(`'${name}' Has Been Removed From The System.`);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      window.location.reload();
    }
  };

  return (
    <main className="reports">
      <Header />
      <section className="reportsContent">
        {sites.map(function (d, i) {
          // console.log(d);
          return (
            <>
              <div
                className="siteReport"
                onClick={() => showSiteTraining(d.site)}
              >
                <p>
                  {d.site} <span>has completed</span>
                  {d.percentage}%
                </p>
              </div>
            </>
          );
        })}
      </section>
      {showSites ? (
        <div className="employees">
          <hr />
          <h1>{site}</h1>
          <h4>{employeesData.length} Employees are registered in this store</h4>
          <ul>
            <li>There is {managers.length} managers, with an average of {managerAv}%</li>
            <li>There is {supervisors.length} supervisors, with an average {superAv}%</li>
            <li>There is {csas.length} CSA, with an average of {csaAv}%</li>
            <li>There is {emails} emails recorded, on a total of {employeesData.length} employees registered</li>
            <li>Login Rate For The Staff Training Is {((staffLogged.logged/employeesData.length)*100).toFixed(0)}%</li>
          </ul>

          <hr />

          <div style={{ padding: "10px", display: "flex", justifyContent: "space-between" }}>

            {parentTasksAllLM ?
              <div style={{ border: "2px solid black" }}>
                <p style={{ backgroundColor: "#BF1a1a", fontWeight: "bolder", color: "white", padding: "10px", marginTop: "-0.07px" }}>Top 5 Tasks Last Month</p>
                {parentTasksAllLM.map(function (d, i) {
                  return (
                    <p style={{ borderBottom: "1px solid black", borderTop: "1px solid black", backgroundColor: "#f9f9f9" }}>{d.name} - {d.total}</p>
                  )
                })}
                {parentTasks && parentTasks.thisMonth ? <div>
                  <p style={{ backgroundColor: "#BF1a1a", fontWeight: "bolder", color: "white", padding: "10px" }}>Total Last Month | {parentTasks.lastMonth.length}</p>
                </div> : <></>}

              </div> : <></>}

            {parentTasksAllTM ?
              <div style={{ border: "2px solid black" }}>
                <p style={{ backgroundColor: "#BF1a1a", fontWeight: "bolder", color: "white", padding: "10px", marginTop: "-0.07px" }}>Top 5 Tasks This Month</p>
                {parentTasksAllTM.map(function (d, i) {
                  return (
                    <p style={{ borderBottom: "1px solid black", borderTop: "1px solid black", backgroundColor: "#f9f9f9" }}>{d.name} - {d.total}</p>
                  )
                })}
                {parentTasks && parentTasks.thisMonth ? <div >
                  <p style={{ backgroundColor: "#BF1a1a", fontWeight: "bolder", color: "white", padding: "10px" }}>Total This Month | {parentTasks.thisMonth.length}</p>
                </div> : <></>}
              </div> : <></>}

            {childTasksAllLM ?
              <div style={{ border: "2px solid black" }}>
                <p style={{ backgroundColor: "#BF1a1a", fontWeight: "bolder", color: "white", padding: "10px", marginTop: "-0.07px" }}>Top 5 Sub-Tasks Last Month</p>
                {childTasksAllLM.map(function (d, i) {
                  return (
                    <p style={{ borderBottom: "1px solid black", borderTop: "1px solid black", backgroundColor: "#f9f9f9" }}>{d.name} - {d.total}</p>
                  )
                })}
                {childTasks && childTasks.thisMonth ? <div>
                  <p style={{ backgroundColor: "#BF1a1a", fontWeight: "bolder", color: "white", padding: "10px" }}>Total Last Month | {childTasks.lastMonth.length}</p>
                </div> : <></>}
              </div> : <></>}

            {childTasksAllTM ?
              <div style={{ border: "2px solid black" }}>
                <p style={{ backgroundColor: "#BF1a1a", fontWeight: "bolder", color: "white", padding: "10px", marginTop: "-0.07px" }}>Top 5 Sub-Tasks This Month</p>
                {childTasksAllTM.map(function (d, i) {
                  return (
                    <p style={{ borderBottom: "1px solid black", borderTop: "1px solid black", backgroundColor: "#f9f9f9" }}>{d.name} - {d.total}</p>
                  )
                })}
                {childTasks && childTasks.thisMonth ? <div>
                  <p style={{ backgroundColor: "#BF1a1a", fontWeight: "bolder", color: "white", padding: "10px" }}>Total This Month | {childTasks.thisMonth.length}</p>
                </div> : <></>}
              </div> : <></>}

            <div style={{ border: "2px solid black" }}>
              <p style={{ backgroundColor: "#BF1a1a", fontWeight: "bolder", color: "white", padding: "10px", marginTop: "-0.07px" }}>Staff Stats</p>

              {newStaff && newStaff.thisMonth ? <div>
                <p style={{ borderBottom: "1px solid black", borderTop: "1px solid black", backgroundColor: "#f9f9f9" }}>New This Month - {newStaff.thisMonth.length}</p>
                <p style={{ borderBottom: "1px solid black", borderTop: "1px solid black", backgroundColor: "#f9f9f9" }}>New Last Month - {newStaff.lastMonth.length}</p>
              </div> : <></>}

              {oldStaff && oldStaff.thisMonth ? <div>
                <p style={{ borderBottom: "1px solid black", borderTop: "1px solid black", backgroundColor: "#f9f9f9" }}>Removed This Month - {oldStaff.thisMonth.length}</p>
                <p style={{ borderBottom: "1px solid black", borderTop: "1px solid black", backgroundColor: "#f9f9f9" }}>Removed Last Month - {oldStaff.lastMonth.length}</p>
              </div> : <></>}<br />

              {staffLogged && staffLogged.logged ? <div>
                <p style={{ backgroundColor: "#BF1a1a", fontWeight: "bolder", color: "white", padding: "10px" }}>Staff Logged Into System | {staffLogged.logged}</p>
              </div> : <></>}
            </div>
          </div>

          {employeesData.map(function (d, i) {
            var hs;
            d.area1
              ? (hs = <DoneIcon fontSize="medium" className="tick" />)
              : (hs = <CloseIcon fontSize="medium" className="cross" />);
            var hot;
            d.area2
              ? (hot = <DoneIcon fontSize="medium" className="tick" />)
              : (hot = <CloseIcon fontSize="medium" className="cross" />);
            var ms;
            d.area3
              ? (ms = <DoneIcon fontSize="medium" className="tick" />)
              : (ms = <CloseIcon fontSize="medium" className="cross" />);
            var fa;
            d.area4
              ? (fa = <DoneIcon fontSize="medium" className="tick" />)
              : (fa = <CloseIcon fontSize="medium" className="cross" />);
            return (
              <div className="employee">
                <div className="mainProgress" style={{ height: "1%" }}>
                  <CircularProgressbar
                    value={d.percent}
                    text={`${d.percent}%`}
                  />
                </div>
                <p key={i}>
                  <span
                    style={{ color: "#1d1d1d", textTransform: "uppercase" }}
                  >
                    {d.name}
                  </span>
                  <span className="site">{d.site}</span>
                  <div className="shown">
                    <span>H&amp;S</span>
                    <span>{hs}</span>
                  </div>
                  <div className="Notshown">
                    <span>Floor</span>
                    <span>{hot}</span>
                  </div>
                  <div className="shown">
                    <span>Alcohol</span>
                    <span>{ms}</span>
                  </div>
                  <div className="shown">
                    <span>Tills</span>
                    <span>{fa}</span>
                  </div>
                </p>
                <div style={{ marginTop: "15px" }}>
                  <Tooltip title="Remove Staff">
                    <button
                      onClick={() => {
                        removeStaff(d.name, d.role, d.site);
                      }}
                    >
                      <PersonRemoveIcon />
                    </button>
                  </Tooltip>
                  <Tooltip title="View Employee">
                    <button>
                      <a
                        href={`/employee?name=${d.name}&role=${d.role}&site=${d.site}`}
                      >
                        <VisibilityIcon />
                      </a>
                    </button>
                  </Tooltip>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </main>
  );
}
