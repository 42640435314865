import React, { useState, useRef, useEffect } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import axios from 'axios'
import ReactToPrint from "react-to-print";

export default function AlcoholDocument(props) {

    const [signature, setSignature] = useState("")
    const [licenseNo, setLicenseNo] = useState("")
    const [trainerName, setTrainerName] = useState("")
    const componentRef = useRef();
    const myRefname = useRef(null);

    useEffect(() => {
        axios.get(`https://backend.training.racetrackpitstop.co.uk/trainingAdmin/code/alcohol?code=${props.code}&admin=true`).then((res) => {
            setTrainerName(res.data.trainerData.name)
            setLicenseNo(res.data.trainerData.license)
        }).catch((err) => {
            console.log(err)
        })
    }, [props.code])

    const sign = () => {
        const data123 = signature.getTrimmedCanvas().toDataURL('image/png')
        axios.post("https://backend.training.racetrackpitstop.co.uk/trainingAdmin/alcohol/signature", { code: props.code, src: data123 }).then((res) => {
            console.log(res.data)
            alert("Document Signed!")
            window.location.reload();
        }).catch((err) => {
            console.log("err")
        })
    }

    const linkToPrint = () => {
        return (
            <button ref={myRefname} style={{ display: "none" }}>Print PDF</button>
        )
    }

    const handleClick = () => {
        myRefname.current.click();
    }

    const clearSignature = () => {
        signature.clear();
    }

    return (
        <>
            <div id="licenseDocument" ref={componentRef} style={{ paddingLeft: "60px", paddingRight: "60px" }}>
                <div className="header" style={{ textAlign: "center" }}>
                    <h3><b>SCHEDULE - FORM OF TRAINING RECORD</b></h3>
                    <h4>LICENSING (SCOTLAND) ACT 2005</h4>
                    <h4><b>Training of Staff – Training Record</b></h4>
                    <h5><b>Member of Staff:</b> <span><u style={{ textDecorationStyle: "dotted" }}>{props.staffName}</u></span></h5>
                </div>
                <div className="actBox" style={{ border: "1px solid black", marginLeft: "10px", marginRight: "10px", padding: "10px" }}>
                    <h5><b>The Act - Schedule 3</b></h5>
                    <ol>
                        <li>No person (other than a person who holds a personal licence) is to work in the premises in the capacity mentioned in sub-paragraph (2) unless that person has
                            complied with such requirements as to the training of staff as may be prescribed for the purposes of this paragraph.</li><br />
                        <li>
                            That is a capacity (whether paid or unpaid) which involves the person –<br />
                            <b>(a)</b> making sales of alcohol, or<br />
                            <b>(b)</b> where alcohol is sold on the premises
                        </li>
                    </ol>
                </div>
                <br/>
                <div className='mup' style={{ border: "1px solid black", marginLeft: "10px", marginRight: "10px", padding: "10px" }}>
                    <h5>Policy on Minimum Unit Pricing (MUP)</h5>
                    <p style={{ display: "block" }}>In order to comply with MUP the following points must be complied with at all times:
                        <ul>
                            <li>The price per unit is 65 pence</li>
                            <li>All alcohol products must be sold at or above its MUP. </li>
                            <li>All signage and promotions must be checked so that alcohol is not advertised or sold at a price below MUP.</li>
                            <li>Neither discount coupons nor special offer may allow the price of an alcoholic product to be reduced below the MUP</li>
                            <li>Any licensing standards officers or police officers checking the pricing of alcoholic products should be provided with whatever reasonable assistance is required.</li>
                            <li>Any questions should be raised with the DPM or other member of management.</li>
                        </ul>
                    </p>
                </div>
                <div className="declaration">
                    <h4><u>DECLARATION</u></h4>
                    <h4>TRAINEE</h4>
                    <p style={{ display: "block" }}>I  <u style={{ textDecorationStyle: "dotted" }}><b>....{props.staffName}....</b></u> confirm that I have received the training required by the Licensing (Training of Staff) (Scotland) Regulations 2007.</p>
                    <p style={{ display: "block" }}><b>Signature:</b>
                        {!props.signed ?
                            <div className="signaturePad">
                                <SignatureCanvas penColor='black' canvasProps={{className: 'sigCanvas'}} ref={(ref) => { setSignature(ref) }} />
                                <button onClick={() => { clearSignature() }}>Clear</button>
                                <button onClick={() => { sign() }}>Sign</button>
                            </div>
                            : <img src={props.signature} style={{ borderBottom: "1px dotted black", marginLeft: "15px" }} height="60" width="150" />
                        }
                    </p>
                    <h4>TRAINER</h4>
                    <p style={{ display: "block" }}>I <span><u style={{ textDecorationStyle: "dotted" }}>....{trainerName}....</u></span> confirm that I have provided the
                        above named member of staff with the training required under the Licensing (Training of Staff) (Scotland) Regulations 2007 on
                        <span> <u style={{ textDecorationStyle: "dotted" }}>....{props.signed ? <b>{props.date}</b> : <b>{new Date().toDateString()}</b>}....</u></span> </p>
                    <p style={{ display: "block" }}><b>Signature: </b><span id="trainerSignature"><u style={{ textDecorationStyle: "dotted" }}>{trainerName}</u></span> <span style={{ fontSize: "0.8em", marginLeft: "15px" }}>(electronically signed)</span></p>
                    <p style={{ display: "block" }}>Where signatory is the holder of a Personal Licence issued under the Licensing (Scotland) Act 2005, please insert the number and the name of Licensing Board
                        which issued the licence here <span><u style={{ textDecorationStyle: "dotted" }}>....{licenseNo}....</u></span></p>
                        <p style={{ display: "block" }}><span><u style={{ textDecorationStyle: "dotted" }}>....{trainerName}....</u></span>
                        takes compliance with the Licensing (Scotland) Act 2005 and the terms of the premises licence very seriously. We
                        recognise that doing so allows us to operate our premises in a responsible manner.
                        As licence holders, we require our staff to observe the terms of this Policy on Minimum Unit Pricing.
                    </p>
                    <p>It is the responsibility of the named premises manager (DPM), or if he or she is not on shift, the person responsible
                        for the management of the premises at that time, to ensure all staff are aware of this policy.</p>
                    <p>Failure to observe the terms of this policy could jeopardise the premises licence and the personal licence of
                        individuals involved as your local LSO may issue warnings or call for a review of the licence. Allowing alcohol to be
                        sold at less than the MUP ultimately is a criminal offence under the 2005 Act.</p>
                    <p>Where signatory is a person other than the holder of a Personal Licence described above, please provide details of the qualification held and accredited by the Scottish
                        Qualifications Authority for the purposes of regulation 2(b) of the Licensing (Training of Staff) (Scotland) Regulations 2007. The name of any company by which the
                        person is employed to provide training should also be given.</p>
                    <p style={{ display: "block" }}>Name Of Company: <span><u style={{ textDecorationStyle: "dotted" }}> ....RaceTrack....</u></span></p><hr />
                </div>
            </div>
            {props.signed ?
                <div>
                    <button onClick={() => handleClick()} style={{ width: "100%", padding: "10px", color: "white", backgroundColor: "darkgreen", border: "3px solid black" }}>Print Document</button>
                    <ReactToPrint documentTitle={`${props.staffName}_${props.code}-alcohol_training`} trigger={linkToPrint} content={() => componentRef.current} />
                </div>
                : <></>
            }
            <button onClick={() => props.close()} style={{ width: "100%", padding: "10px", color: "white", backgroundColor: "#BF1a1a", border: "3px solid black" }}>Close Document</button>
        </>
    )
}