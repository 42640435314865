import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../Header";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";

function AddEmployee() {
  const [fullname, setFullname] = useState("");
  const [store, setStore] = useState("");
  const [position, setPosition] = useState("");
  const [HO, setHO] = useState(false);
  const [sites, setSites] = useState([]);
  const [showEmployee, setShowEmployee] = useState(false);
  const [showUser, setShowUser] = useState(false);

  useEffect(() => {
    axios
      .get("https://nitrous.rt-itservices.co.uk/sites/data")
      .then((res) => {
        // console.log(res.data)
        setSites(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  async function send() {
    if (fullname.length === 0) {
      alert("Name of employee must be filled");
    } else {
      var newStaff = {
        name: fullname,
        site: store,
        role: position,
      };
      //console.log(newStaff)
      await axios
        .post("https://backend.training.racetrackpitstop.co.uk/new/", newStaff)
        .then((res) => {
          console.log(res.data);
          alert("New Staff member added");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }


  return (
    <div>
      <Header />
      <div className="newEmployee">
        <h1>Add a New Employee</h1>
        {/* <form> */}
        <div className="form">
          <label>Full Name of New Employee</label>
          <input
            type="text"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
            className="input"
            required
          />
          <label>Select your Store</label>
          <select
            className="input"
            value={store}
            onChange={(e) => setStore(e.target.value)}
          >
            <option>--Your store--</option>
            {sites.map(function (d, i) {
              return <option>{d.name}</option>;
            })}
            <option value="Head Office">Head Office</option>
          </select>
          {store !== "Head Office" ? (
            <>
              <label>Select Position of Employee</label>
              <select
                className="input"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              >
                <option>Position of new employee</option>
                <option value="CSA">Customer Service Assistant</option>
                <option value="Supervisor">Supervisor</option>
                <option value="Manager">Manager</option>
              </select>
            </>
          ) : (
            <>
              <label>Select Department of Employee</label>
              <select
                className="input"
                onChange={(e) => setPosition(e.target.value)}
              >
                <option>Position of new employee</option>
                <option>IT</option>
                <option>HR</option>
                <option>Admin</option>
                <option>Packers</option>
                <option>Builders</option>
              </select>
            </>
          )}
          <Button
            variant="outlined"
            startIcon={<SendIcon />}
            onClick={() => send()}
            color="error"
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AddEmployee;
