import "./login.scss";
import logo from "../../images/trainingLogo.png";
import { useState } from "react";
import axios from "axios";
import Alert from "@mui/material/Alert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function Login() {
  const [user, setUser] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(false);
  const [form, setForm] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [newPassCheck, setNewPassCheck] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [matchAlert, setMatchAlert] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    const loginUser = { name: user, password: password };
    let attempts = 0;
    await axios
      .post("https://backend.training.racetrackpitstop.co.uk/trainingAdmin/login", loginUser)
      .then((res) => {
        if (res.data.length === 0) {
          setError(true);
          setTimeout(() => {
            setError(false);
          }, 2500);
          attempts++;
        } else {
          console.log(res.data);
          localStorage.setItem("employees", JSON.stringify(res.data));
          localStorage.setItem("user", user);
          window.location.href = "/home";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handlePass() {
    if (form) {
      setForm(false);
    } else {
      setForm(true);
    }
  }

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  function passChange() {
    let data = {
      name: user,
      newPass: newPassword,
    };
    axios
      .post("https://backend.training.racetrackpitstop.co.uk/trainingAdmin/changePass", data)
      .then((res) => {
        console.log(res.data);

      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <main className="login">
      <img src={logo} alt="logo" />
      {error ? (
        <Alert
          severity="error"
          sx={{ width: "80vw", margin: "auto", marginBottom: "2%" }}
        >
          Incorrect credentials, please try again
        </Alert>
      ) : (
        <></>
      )}
      {matchAlert ? (
        <Alert
          severity="error"
          sx={{ width: "80vw", margin: "auto", marginBottom: "2%" }}
        >
          New Password cannot be the same as old password
        </Alert>
      ) : (
        <></>
      )}
      {form ? (
        <>
          <h1>Please login</h1>

          <form onSubmit={submit}>
            <input
              type="text"
              placeholder="Username"
              onChange={(e) => setUser(e.target.value)}
            />
            <br />
            {/* <div className="inline"> */}
            <input
              type={passwordType}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {/* <div onClick={() => togglePassword}>
              {passwordType === "password" ? (
                <VisibilityOffIcon sx={{color: "#404040"}}/>
              ) : (
                <VisibilityIcon sx={{color: "#404040"}}/>
              )}
            </div> */}
            {/* </div> */}
            <br />
            <input type="submit" value="Login" />
          </form>
        </>
      ) : (
        <>
          <h1>Change password</h1>
          <form onSubmit={passChange}>
            <input
              type="text"
              placeholder="Username"
              onChange={(e) => setUser(e.target.value)}
            />
            <br />
            <input
              type={passwordType}
              placeholder="New Password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <br />
            <input type="submit" value="Change Password" />
          </form>
        </>
      )}
      <button class="pass" onClick={() => handlePass()}>
        {!form ? <p>Login</p> : <p> Forgot Password</p>}
      </button>
    </main>
  );
}
