import React,{useState} from 'react'

export default function ReviewQuestion(props){

    const [showAnswer,setShowAnswer]=useState(false)

    return(
        <div style={{marginBottom:"5px"}}>
            <p style={{backgroundColor:props.colour,color:"white",borderRadius:"10px",fontSize:"1.2em",padding:"3px"}} onClick={()=>setShowAnswer(!showAnswer)}>{props.question}</p>
            {showAnswer?<><span><b>Your Answer: </b>{props.answer}</span>{props.colour==="red"?<><hr/><span><b>Correct Answer:</b> {props.correct}</span></>:<></>}</>:<></>}
        </div>
    )
}