import React, { useState, useEffect, useRef } from "react";
import Header from "../Header";
import axios from "axios";
import TaskGroup from "../TaskGroup";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ReviewQuestion from "../ReviewQuestion";
import SignatureCanvas from "react-signature-canvas";
// import AlcoholDocument from "../AlcoholDocument";
import AlcoholDocumentBoth from "../AlcoholDocumentBoth";
import AlcoholDocumentPrint from "../AlcoholDocumentPrint";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import DownloadIcon from "@mui/icons-material/Download";
import ReactToPrint from "react-to-print";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  maxHeight: "40vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  "@media(max-width: 600px)": {
    width: "80vw",
    height: "40vh",
  },
};

const emailStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "25vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  "@media(max-width: 600px)": {
    width: "80vw",
    height: "40vh",
  },
};

export default function Employee() {
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [site, setSite] = useState("");
  const [updated, setUpdated] = useState(false);
  const [manager, setManager] = useState(false);
  const [showAlcohol, setShowAlcohol] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [showGenerate, setShowGenerate] = useState(false);
  const [percent, setPercent] = useState(0);
  const [code, setCode] = useState("");
  const [hasCode, setHasCode] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showSigned, setShowSigned] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [signature, setSignature] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [showDocument, setShowDocument] = useState("");
  const [date, setDate] = useState("");
  const [alcohol, setAlcohol] = useState("#BF1a1a");
  const [cash, setCash] = useState("#BF1a1a");
  const [cashingUp, setCashingUp] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const componentRef = useRef();
  const myRefname = useRef(null);
  const [signCashUp, setSignCashUp] = useState(false);
  const [cashSignature, setCashSignature] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);
  const [sites, setSites] = useState([]);
  const [newSite, setNewSite] = useState();

  const [openEmail, setOpenEmail] = useState(false);
  const handleOpenEmail = () => setOpenEmail(true);
  const handleCloseEmail = () => {
    setEmail("");
    setOpenEmail(false);
  };
  const [email, setEmail] = useState("");
  const [emailAlert, setEmailAlert] = useState(false);
  const [message, setMessage] = useState("")

  useEffect(async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const n = queryParams.get("name");
    const s = queryParams.get("site");
    const r = queryParams.get("role");
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/code?name=${n}&role=${r}&site=${s}`
      )
      .then(async (res) => {
        if (res.data.code !== "") {
          setCode(res.data.code);
          var justCode = false;
          await axios
            .get(
              `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/code/alcohol?code=${res.data.code}&admin=true`
            )
            .then((response) => {
              if (response.data.signed) {
                setAlcohol("#7dd17d");
                setShowSigned(true);
                setImageSrc(response.data.signature);
                setDate(response.data.signedDate);
              } else if (response.data.reviewed) {
                setShowGenerate(true);
              } else if (response.data.answered) {
                setShowReview(true);
                setAnswers(response.data.quiz);
              } else {
                justCode = true;
              }
            })
            .catch((err) => {
              console.log(err);
            });
          if (justCode) {
            setHasCode(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    await axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/employee?name=${n}&role=${r}&site=${s}`
      )
      .then((res) => {
        setData(res.data);
        console.log(res.data);

        if (r === "Manager" || res.data.managerTasks) {
          setManager(true);
        }
        if (res.data.cashingUp === true) {
          setCash("#7dd17d");
          setSignCashUp(true);
          setCashSignature(res.data.cashingSignature);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    await axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/completionRate?name=${n}&role=${r}&site=${s}`
      )
      .then((res) => {
        setPercent(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setName(n);
    setRole(r);
    setSite(s);

    await axios
      .get("https://nitrous.rt-itservices.co.uk/sites/data")
      .then((res) => {
        // console.log(res.data)
        setSites(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [updated]);

  function ChangeUpdate() {
    setUpdated(!updated);
  }

  // function Settings() {
  //   window.location.href = `/employee-seetings?name=${data.name}&role=${data.role}`;
  // }

  const handleChange = async () => {
    const data = { role: role, name: name, site: site };
    if (!manager) {
      await axios
        .post(
          "https://backend.training.racetrackpitstop.co.uk/trainingAdmin/manager-tasks",
          data
        )
        .then((res) => {
          alert("Manager Tasks Enabled");
          ChangeUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .post(
          "https://backend.training.racetrackpitstop.co.uk/trainingAdmin/manager-tasks-rm",
          data
        )
        .then((res) => {
          alert("Manager Tasks Disabled");
          ChangeUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setManager(!manager);
  };

  const generateCode = async () => {
    const data = { role: role, name: name, site: site };
    axios
      .post(
        "https://backend.training.racetrackpitstop.co.uk/trainingAdmin/code/generate",
        data
      )
      .then((res) => {
        if (res.data.generated) {
          setCode(res.data.code);
          setHasCode(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reviewCompleted = () => {
    axios
      .post(
        "https://backend.training.racetrackpitstop.co.uk/trainingAdmin/alcohol/reviewed",
        {
          code: code,
        }
      )
      .then((res) => {
        alert("Review Complete");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sign = () => {
    const data123 = signature.getTrimmedCanvas().toDataURL("image/png");
    axios
      .post(
        "https://backend.training.racetrackpitstop.co.uk/trainingAdmin/alcohol/signature",
        {
          code: code,
          src: data123,
        }
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log("err");
      });
    console.log(data123);
    setImageSrc(data123);
  };

  const close = () => {
    setShowDocument(false);
  };

  const linkToPrint = () => {
    return (
      <button ref={myRefname} style={{ display: "none" }}>
        Print PDF
      </button>
    );
  };
  const handleCashingUp = () => {
    const data123 = signature.getTrimmedCanvas().toDataURL("image/png");
    axios
      .post(
        "https://backend.training.racetrackpitstop.co.uk/trainingAdmin/cashingUp",
        {
          src: data123,
          staffTrainingId: data.staffTrainingId,
        }
      )
      .then((res) => {
        // console.log(res.data);
        alert("Document has been sent to HR");
        window.location.reload();
      })
      .catch((err) => {
        console.log("err");
      });
  };
  const clearSignature = () => {
    signature.clear();
  };
  const handleClick = () => {
    myRefname.current.click();
  };

  function handleEdit() {
    var toEdit = {
      staffTrainingId: data.staffTrainingId,
      site: newSite,
    };
    axios
      .post(
        "https://backend.training.racetrackpitstop.co.uk/trainingAdmin/updateSite",
        toEdit
      )
      .then((res) => {
        alert("Site changed");
        window.location.href = "/home";
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const addEmail = () => {
    var emailUpdate = {
      staffTrainingId: data.staffTrainingId,
      email: email,
    };
    if (
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      axios
        .post(
          "https://backend.training.racetrackpitstop.co.uk/trainingAdmin/updateEmail",
          emailUpdate
        )
        .then((res) => {
          handleCloseEmail()
          ChangeUpdate()
        })
        .catch((err) => {
          console.log(err);
          setMessage("Could not update, please try again")
          setEmailAlert(true);
          setTimeout(() => {
            setEmailAlert(false);
          }, 2500);
        });
    } else {
      setMessage("Email does not have the right format, please try again")
      setEmailAlert(true);
      document.querySelector("input").focus();
      setTimeout(() => {
        setEmailAlert(false);
      }, 2500);
    }
  };

  return (
    <>
      <Header />
      <div className="employeeTraining">
        <h1>Employee Training List</h1>
        <div id="employeeName">
          <h2>
            {data.name} - {data.role} @ {data.site}
            <EditIcon
              sx={{ color: "#fff", marginLeft: "2%", cursor: "pointer" }}
              onClick={handleOpenEdit}
            />
          </h2>
          {data.email ? (
            <h2>{data.email}</h2>
          ) : (
            <h2>
              No Email{" "}
              <AddCircleOutlineIcon
                sx={{ color: "#fff", marginLeft: "2%", cursor: "pointer" }}
                onClick={handleOpenEmail}
              />
            </h2>
          )}
          <Modal
            open={openEdit}
            onClose={handleCloseEdit}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <h2 style={{ color: "#000" }}>Change Site</h2>
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Stack direction="column">
                  <h3>Select which site this employee works at now:</h3>
                  <select
                    className="input"
                    onChange={(e) => setNewSite(e.target.value)}
                  >
                    <option>--Select New Store--</option>
                    {sites.map(function (d, i) {
                      return <option>{d.name}</option>;
                    })}
                    <option value="Head Office">Head Office</option>
                  </select>
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={() => handleEdit()}
                    sx={{ marginTop: "3%" }}
                  >
                    Update
                  </Button>
                </Stack>
              </Typography>
            </Box>
          </Modal>
          <Modal
            open={openEmail}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={emailStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <h2 style={{ color: "#000" }}>Add Email</h2>
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Stack>
                  {emailAlert ? (
                    <Alert severity="error">
                      <AlertTitle>Error</AlertTitle>
                      {message}
                    </Alert>
                  ) : (
                    <></>
                  )}
                  <h3>Please type email:</h3>
                  <input
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="example@gmail.com"
                  />
                  <Stack direction="row" spacing={2}>
                    <Button
                      variant="outlined"
                      color="success"
                      onClick={() => addEmail()}
                      sx={{ marginTop: "3%" }}
                      endIcon={<SendIcon />}
                    >
                      send
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={handleCloseEmail}
                      sx={{ marginTop: "3%" }}
                      endIcon={<CancelIcon />}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              </Typography>
            </Box>
          </Modal>
        </div>
        <br />
        <div>
          <h2
            style={{
              backgroundColor: "#BF1a1a",
              padding: "10px",
              border: "1px solid black",
              width: "50%",
              margin: "auto",
              borderRadius: "10px",
            }}
          >
            Staff ID - {data.staffTrainingId}
          </h2>
        </div>
        <div className="mainProgress" style={{ height: "2%" }}>
          <CircularProgressbar value={percent} text={`${percent}%`} />
        </div>
        <br />

        <table id="tasks">
          <tbody>
            {site !== "Head Office" ? (
              <div
                id="alcoholTraining"
                style={{
                  margin: "0 auto",
                  boxShadow: "0 0 2px 2px darkgrey",
                  borderRadius: "10px",
                  backgroundColor: "#f2f2f2",
                  padding: "5px",
                }}
              >
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginRight: "20px",
                    marginLeft: "20px",
                    backgroundColor: `${alcohol}`,
                    border: "2px solid black",
                    borderRadius: "10px",
                  }}
                >
                  <h3
                    onClick={() => {
                      setShowAlcohol(!showAlcohol);
                    }}
                    style={{ textAlign: "center", color: "white" }}
                  >
                    Alcohol Training
                  </h3>
                  {showAlcohol ? (
                    <div>
                      <hr />
                      {
                        showReview ? (
                          <div>
                            <h3
                              style={{
                                display: "block",
                                margin: "0 auto",
                                textAlign: "center",
                                backgroundColor: "white",
                                width: "40%",
                                borderRadius: "15px",
                                marginBottom: "10px",
                                border: "1px solid black",
                              }}
                              onClick={() =>
                                setShowReviewModal(!showReviewModal)
                              }
                            >
                              Review Answers
                            </h3>
                            {showReviewModal ? (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "45vh",
                                  left: "10%",
                                  width: "80%",
                                  backgroundColor: "lightgray",
                                  border: "1px solid #BF1a1a",
                                  padding: "10px",
                                  zIndex: "10",
                                }}
                              >
                                <p>
                                  <u>Your Answers</u>
                                </p>
                                {answers.map(function (d, i) {
                                  var bgColour;
                                  d.isCorrect
                                    ? (bgColour = "green")
                                    : (bgColour = "red");
                                  return (
                                    <ReviewQuestion
                                      colour={bgColour}
                                      question={d.questionText}
                                      answer={d.answerText}
                                      correct={d.correctAnswer}
                                    />
                                  );
                                })}
                                <h3
                                  style={{
                                    margin: "0 auto",
                                    textAlign: "center",
                                    backgroundColor: "white",
                                    width: "40%",
                                    borderRadius: "15px",
                                    marginTop: "10px",
                                    marginBottom: "5px",
                                    border: "1px solid black",
                                  }}
                                  onClick={() => reviewCompleted()}
                                >
                                  Complete Review
                                </h3>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : showGenerate ? (
                          <>
                            <h3
                              style={{
                                margin: "0 auto",
                                textAlign: "center",
                                backgroundColor: "white",
                                width: "40%",
                                borderRadius: "15px",
                                marginBottom: "10px",
                                border: "1px solid black",
                              }}
                              onClick={() => setShowDocument(!showDocument)}
                            >
                              Sign Document
                            </h3>
                            {showDocument ? (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "45vh",
                                  left: "10%",
                                  backgroundColor: "white",
                                  zIndex: "11",
                                  border: "1px solid black",
                                  width: "80%",
                                }}
                              >
                                <AlcoholDocumentBoth
                                  close={close}
                                  code={code}
                                  staffName={name}
                                  signed={false}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : showSigned ? (
                          <>
                            <h3
                              style={{
                                margin: "0 auto",
                                textAlign: "center",
                                backgroundColor: "white",
                                width: "40%",
                                borderRadius: "15px",
                                marginBottom: "10px",
                                border: "1px solid black",
                              }}
                              onClick={() => setShowDocument(!showDocument)}
                            >
                              View Document
                            </h3>
                            {showDocument ? (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "45vh",
                                  left: "10%",
                                  backgroundColor: "white",
                                  zIndex: "11",
                                  border: "1px solid black",
                                  width: "80%",
                                }}
                              >
                                <AlcoholDocumentPrint
                                  close={close}
                                  code={code}
                                  staffName={name}
                                  signed={true}
                                  signature={imageSrc}
                                  date={date}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <p style={{ color: "white" }}>
                            To Complete This Section, Use The Staff ID To Access
                            The{" "}
                            <a
                              style={{
                                textDecoration: "underline",
                                color: "white",
                              }}
                              href="https://training.racetrackpitstop.co.uk"
                              target="_blank"
                            >
                              Training System
                            </a>
                          </p>
                        )
                        // hasCode ?
                        //   <p style={{ color: "white" }}>Unique Code - <b>{code}</b></p>
                        //   :
                        //   <h3 style={{ margin: "0 auto", textAlign: "center", backgroundColor: "white", width: "40%", borderRadius: "15px", marginBottom: "10px", border: "1px solid black" }} onClick={() => generateCode()}>Generate Code</h3>
                      }
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {data.role === "Manager" ? (
              <div
                className="cashingUp"
                style={{
                  marginTop: "3%",
                  margin: "0 auto",
                  boxShadow: "0 0 2px 2px darkgrey",
                  borderRadius: "10px",
                  backgroundColor: "#f2f2f2",
                  padding: "5px",
                }}
                onClick={() => setCashingUp(true)}
              >
                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginRight: "20px",
                    marginLeft: "20px",
                    backgroundColor: `${cash}`,
                    border: "2px solid black",
                    borderRadius: "10px",
                  }}
                >
                  <h3 style={{ textAlign: "center", color: "white" }}>
                    Cashing Up Policy
                  </h3>
                  {cashingUp ? (
                    <div onClick={handleOpen}>
                      <hr />
                      <h6
                        style={{
                          textAlign: "center",
                          color: "white",
                          textTransform: "uppercase",
                          cursor: "pointer",
                        }}
                      >
                        Sign Policy
                      </h6>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-title"
                            variant="h4"
                            component="h1"
                          ></Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 0.5, fontSize: "0.9rem" }}
                          >
                            <Stack
                              ref={componentRef}
                              style={{ margin: "50px 40px" }}
                            >
                              <h4
                                style={{
                                  textAlign: "center",
                                  textTransform: "uppercase",
                                }}
                              >
                                Cashing Up Policy for Managers
                              </h4>
                              <p>
                                In regards to counting cash, end of days and
                                reconciliations, please read the below and sign
                                to confirm that you fully understand.
                              </p>
                              <p>
                                As a site manager it is your responsibility to
                                manage and maintain good practice while dealing
                                with cash takings at your store. The below
                                instructions are being communicated with all
                                Management to alleviate concerns and issues that
                                are consistently arising due to mishandling of
                                cash.
                              </p>
                              <ol>
                                <li>
                                  Once drop areas have been emptied you must
                                  immediately take the drops to the office to be
                                  counted. While making your way to the office,
                                  you should not stop or be distracted by other
                                  staff or tasks.
                                </li>
                                <li>
                                  Once the drop areas have been emptied and the
                                  cash is in your possession, you become wholly
                                  responsible for this money and will be held
                                  accountable for any discrepancies or missing
                                  takings.
                                </li>
                                <li>
                                  While the cash is being counted in the office,
                                  there should be no distractions. It should
                                  also not be left while being counted, to
                                  complete another task and returned to at a
                                  later time. There should not be multiple staff
                                  members in the office while this is being
                                  completed as talking while counting can lead
                                  to inaccurate amounts being noted.
                                </li>
                                <li>
                                  Any missing cash or takings which are
                                  unaccounted for must be declared and
                                  investigated as a matter of urgency by the
                                  site Manager. After a thorough investigation,
                                  if the money cannot be accounted for you
                                  should only then contact IT to investigate
                                  CCTV if you cannot do this yourself. Accounts
                                  should also be notified of the outcome of your
                                  investigation.
                                </li>
                                <li>
                                  End of Days should be completed within 48
                                  hours and not left to pile up as this is how
                                  mistakes occur and discrepancies cannot be
                                  accounted for.
                                </li>
                              </ol>
                              <p>
                                If you do not understand the above given
                                instructions or have any concerns regarding
                                these points, please get in touch with the HR to
                                discuss this further.
                              </p>
                              {!signCashUp ? (
                                <div
                                  className="signaturePad"
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <SignatureCanvas
                                    penColor="black"
                                    canvasProps={{ className: "sigModal" }}
                                    ref={(ref) => {
                                      setSignature(ref);
                                    }}
                                  />
                                  <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{ margin: "2% auto" }}
                                  >
                                    <button
                                      onClick={() => {
                                        clearSignature();
                                      }}
                                      style={{ marginRight: "2%" }}
                                    >
                                      Clear
                                    </button>
                                    <button
                                      onClick={() => {
                                        handleCashingUp();
                                      }}
                                    >
                                      Sign
                                    </button>
                                  </Stack>
                                </div>
                              ) : (
                                <Stack>
                                  <img
                                    src={cashSignature}
                                    style={{
                                      borderBottom: "1px dotted black",
                                      marginLeft: "15px",
                                    }}
                                    height="60"
                                    width="150"
                                  />
                                  <p style={{ marginLeft: "-35%" }}>
                                    {data.name}
                                  </p>
                                  <Button
                                    variant="outlined"
                                    startIcon={<DownloadIcon />}
                                    color="warning"
                                    onClick={() => handleClick()}
                                  >
                                    Download
                                  </Button>
                                  <ReactToPrint
                                    documentTitle={`${data.name}-cashing up policy`}
                                    trigger={linkToPrint}
                                    content={() => componentRef.current}
                                  />
                                </Stack>
                              )}
                            </Stack>
                          </Typography>
                        </Box>
                      </Modal>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            <tr>
              <th>Task</th>
              <th>Date Trained</th>
              <th>Trainer</th>
              <th>Completed</th>
            </tr>
            {data.tasks ? (
              <>
                {data.tasks.map(function (d, i) {
                  // console.log(d);
                  return (
                    <TaskGroup
                      manager={d.managerT}
                      enable={d.enabled}
                      group={d.name}
                      dbCat={d.name}
                      certificate={d.certificate}
                      tasks={d.taskData}
                      name={name}
                      role={role}
                      site={site}
                      updateFunction={ChangeUpdate}
                      it={d.nitroSolutions}
                    />
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </tbody>
        </table>
        <br />
        <hr />
        <br />
      </div>
    </>
  );
}
