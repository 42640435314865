import React, { useState, useEffect } from "react";
import axios from "axios";
import { style } from "@mui/system";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import IconButton from "@mui/material/IconButton";
import { Help } from "@material-ui/icons";

export default function Task(props) {
  const [inputs, setInputs] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [date, setDate] = useState(props.dateTrained);
  const [trainer, setTrainer] = useState(props.trainer);

  useEffect(async () => {
    // console.log(getBrowserSize())
    const browserSize = getBrowserSize();
    if (browserSize.width < 770) {
      setInputs(false);
    }
  }, [props]);

  function getBrowserSize() {
    var e = window,
      a = "inner";
    if (!("innerWidth" in window)) {
      a = "client";
      e = document.documentElement || document.body;
    }
    return { width: e[a + "Width"], height: e[a + "Height"] };
  }

  const updateTask = async (task, field, value, current) => {
    // var val;
    // if (field === "shown" || field === "enabled" || field === "competent") {
    //   if (current === false) {
    //     val = true;
    //   } else {
    //     val = false;
    //   }
    // } else {
    //   val = value;
    // }
    var data = {
      name: props.name,
      role: props.role,
      site: props.site,
      task: task,
      field: "competent",
      value: !current,
      category: props.parent,
      dateTrained: date,
      trainer: trainer,
    };
    if (date !== "" && trainer !== "") {
      await axios
        .post("https://backend.training.racetrackpitstop.co.uk/trainingAdmin/task", data)
        .then(async (res) => {
          props.updateFunction();
          setUpdated(!updated);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert(
        "All fields must be filled before marking this category as complete."
      );
    }
  };
  function ShowInputs() {
    if (inputs === false) {
      setInputs(true);
    } else setInputs(false);
  }
  async function Help() {
    var data = {
      name: props.name,
      role: props.role,
      site: props.site,
      parent: props.parent,
      child: props.task,
    };
    console.log(props.it)
    if (props.it === true) {
      window.location.href ="https://schedule.nitrosolutions.co.uk/"
    } else {
      var x = window.confirm(
        `Do you want to request extra training on this ${props.task} category?`
      );
      if (x === true) {
        await axios
          .post("https://backend.training.racetrackpitstop.co.uk/trainingAdmin/help", data)
          .then((res) => {
            alert("An email has been sent requesting more training");
            window.location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        window.location.reload();
      }
    }
  }

  return (
    <>
      <tr key={props.i} style={{ backgroundColor: props.comp }}>
        <td
          style={{ textAlign: "center", fontWeight: "bolder" }}
          onClick={() => ShowInputs()}
        >
          {props.task}
        </td>
        {inputs ? (
          <>
            <td>
              <input
                className="inputCentre"
                type="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                  // updateTask(props.task, "dateTrained", e.target.value);
                }}
              />
            </td>
            <td>
              <input
                className="inputCentre"
                type="text"
                value={trainer}
                onChange={(e) => {
                  setTrainer(e.target.value);
                  // updateTask(props.task, "trainer", e.target.value);
                }}
              />
            </td>
            <td>
              <input
                className="inputCentre"
                type="checkbox"
                checked={props.completed}
                onChange={(e) => {
                  updateTask(
                    props.task,
                    "competent",
                    e.target.value,
                    props.completed
                  );
                }}
              />
            </td>
            <td>
              <IconButton onClick={() => Help()} className="helpButton">
                <ContactSupportIcon fontSize="large" color="primary" />
              </IconButton>
            </td>
          </>
        ) : (
          <></>
        )}
      </tr>
    </>
  );
}
