import React, { useState, useEffect } from "react";
import Task from "./task";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Switch from '@mui/material/Switch';
import axios from "axios";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Certificate from "./Certificate";
import Modal from "@mui/material/Modal";

export default function TaskGroup(props) {
  const [showBox, setShowBox] = useState(false);
  const [headingColour, setHeadingColour] = useState("#BF1a1a");
  const [borderC, setBorderC] = useState("black");
  const [percentageComplete, setPercentageComplete] = useState(0);
  const [enabled, setEnabled] = useState(props.enable)
  const [showCertificate, setShowCertificate] = useState(false)
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    var trainedCount = 0;
    if (props.tasks) {
      for (let i = 0; i < props.tasks.length; i++) {
        if (props.tasks[i].competent) {
          trainedCount++;
        }
      }
      if (!enabled) {
        setHeadingColour('grey')
      } else if (trainedCount === props.tasks.length) {
        setHeadingColour("#7dd17d");
      } else if (props.manager) {
        setHeadingColour('black')
        setBorderC("#BF1a1a")
      } else {
        setHeadingColour("#BF1a1a");
        setBorderC("black")
      }
      setPercentageComplete(((trainedCount / props.tasks.length) * 100).toFixed(0));
    }
  }, [props, enabled]);

  const a = () => {
    props.updateFunction();
  };

  const handleChange = async () => {
    if (props.group === "H&S" || props.group === "Deliveries" || props.group === "Tills" || props.group === "Floor Maintenance") {
      alert("This Category Is Mandatory & Cannot Be Disabled")
    } else {
      var stat = false;
      if (!enabled) {
        stat = true
      }
      const data = {
        name: props.name,
        site: props.site,
        role: props.role,
        category: props.dbCat,
        status: stat
      }
      await axios.post("https://backend.training.racetrackpitstop.co.uk/trainingAdmin/enable", data).then((res) => {
        setEnabled(!enabled)
        props.updateFunction();
      }).catch((err) => {
        console.log(err)
      })
    }
  }
  const showList = async () => {
    if (enabled) {
      setShowBox(!showBox);
    }
  }

  return (
    <>
      {/* {props.enable? ( */}
      <div className="taskGroup">
        <div
          className="h3"
          style={{
            backgroundColor: headingColour,
            border: `2px solid ${borderC}`
          }}
        // onClick={() => {
        //   setShowBox(!showBox);
        // }}
        >{enabled ? <span className="arrow" onClick={() => { showList() }}>⬇</span> : <></>}
          <h3 onClick={() => { showList() }}>{props.group}</h3>
          <Switch checked={enabled} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
          <div className="progress" style={{ height: "2%" }}>
            <CircularProgressbar value={percentageComplete} text={`${percentageComplete}%`} />
          </div>
        </div>
        {showBox ?
          <>
            {props.tasks.map(function (d, i) {
              var comp;
              d.competent ? (comp = "#7dd17d") : (comp = "");
              return (
                <>
                  <Task
                    parent={props.dbCat}
                    task={d.task}
                    dateTrained={d.dateTrained}
                    completed={d.competent}
                    trainer={d.trainer}
                    comp={comp}
                    i={i}
                    name={props.name}
                    role={props.role}
                    site={props.site}
                    updateFunction={a}
                    it={props.it}
                  />
                </>
              );
            })}
            {props.certificate ?
              <button style={{ padding: "5px", width: "100%" }} onClick={handleOpen}><CloudDownloadIcon sx={{ color: "dodgerblue" }} /></button>
              :
              <></>
            }
          </>
          :
          <></>
        }
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Certificate
            module={props.group}
            tasks={props.tasks}
            date={new Date(props.certificate).toLocaleDateString()}
            name={props.name}
            role={props.role}
            site={props.site} />
        </Modal>

      </div>
      {/* ) : (
        <></>
      )} */}
    </>
  );
}
