import Logo from "../images/trainingLogo.png";
import HomeIcon from "@material-ui/icons/Home";
import IconButton from "@mui/material/IconButton";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Tooltip from "@mui/material/Tooltip";
import LogoutIcon from "@mui/icons-material/Logout";

export default function Header() {
  function home() {
    window.location.href = "/home";
  }

  function logOut(){
    localStorage.clear()
    window.location.href = "/"
  }

  return (
    <header>
      <img
        src={Logo}
        alt="Logo"
        onClick={home}
        style={{ cursor: "pointer" }}
        width="200px"
      />
      <nav>
        <Tooltip title="Home">
          <IconButton href="/home">
            <HomeIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add Employee">
          <IconButton href="/add-employee">
            <PersonAddIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Admin Panel">
          <IconButton href="/reports">
            <AdminPanelSettingsIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Log Out">
          <IconButton onClick={() => logOut()}>
            <LogoutIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </nav>
    </header>
  );
}
