import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './components/Home/Home';
import Employee from './components/Employee/Employee';
import { useState, useEffect } from "react";
import axios from "axios";
import { EmployeeContext } from "./context/EmployeeContext";
import AddEmployee from './components/Add_Employee/AddEmployee';
import EmployeeSettings from './components/Employee/EmployeeSettings';
import Reports from './components/Reports';
import Login from './components/login/Login';

function App() {

  const [employees, setEmployees] = useState([])
  const [page, setPage] = useState(0)


  // useEffect(async() => {
  //   await axios.get(`https://nitrous.rt-itservices.co.uk/training/?page=${page}`).then((res) => {
  //     console.log(res.data)
  //     setEmployees(res.data)
  //     setPage(0)

  //   }).catch((err) => {
  //     console.log(err)
  //   })
  // },[])

  return (
    <Router>
      {/* <EmployeeContext.Provider value={{ employees, setEmployees }}> */}
        <Switch>
          <Route exact path="/" >
            <Login />
          </Route>
          <Route exact path="/home" >
            <Home />
          </Route>
          <Route exact path="/employee">
            <Employee />
          </Route>
          <Route exact path="/add-employee">
            <AddEmployee />
          </Route>
          <Route exact path="/employee-settings">
            <EmployeeSettings />
          </Route>
          <Route exact path="/reports">
            <Reports />
          </Route>
        </Switch>
      {/* </EmployeeContext.Provider> */}
    </Router>
  );
}

export default App;
